import createReducer from "../helpers/createReducer";
import {END_LESSON, START_LESSON, OPEN_THEMES_SIDEBAR} from "../actionTypes/currentLessonPathTpes";


const defaultState = {
    lessonPath:"",
    sidebarVisible:false,
}

const reducers = {
    [START_LESSON](state, {lessonPath}){
        localStorage.setItem('lessonPath',lessonPath)
        return {
            ...state,
            lessonPath
        }
    },
    [END_LESSON](state){
        localStorage.removeItem("lessonPath")
        return{
            lessonPath:''
        }
    },
    [OPEN_THEMES_SIDEBAR](state, {toggle}) {
        return {
            ...state,
            sidebarVisible: toggle
        }
    }
}

export default createReducer(defaultState, reducers);
