import {END_LESSON, START_LESSON, OPEN_THEMES_SIDEBAR} from "../actionTypes/currentLessonPathTpes";


export const initLesson = () => {
    const lessonPath = localStorage.getItem("lessonPath")

    return (dispatch) =>{
        if(lessonPath){
            dispatch({type:START_LESSON,lessonPath});
            return true
        }else {
            return false
        }

    }
}

export const startCurrentLesson = (lessonPath) =>{
    return {
        type:START_LESSON,
        lessonPath
    }
}

export const endCurrenLesson = () => {
    return {
       type:END_LESSON
    }
}

export const themesSidebarToggle = (toggle) => {
    return {
        type: OPEN_THEMES_SIDEBAR,
        toggle
    }
}
